import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/homePage";
import StockPage from "./pages/stockPage";
import AddSchool from "./pages/addForm/add-production-school";
import AddProduction from "./pages/addForm/add-production";
import AddEggLogPage from "./pages/addForm/addEggLog";
import ReportPage from "./pages/reportPage";
// import ReportIncome from "./pages/report-income";
import AddUser from "./pages/user/addUser";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Login from "./pages/login";
import AddLiveStockPage from "./pages/addForm/addLIveStockSlaughter";
import AddDrainage from "./pages/addForm/drainage-and-irrigation";
import DrainageTable from "./pages/addForm/drainage-table";
import ProtectedRoute from "./components/protectedRoute";
import AddDippingAndVaccination from "./pages/dipppingAndVaccination/addDippingAndVaccination";
import DippingAndVaccinationList from "./pages/dipppingAndVaccination/dippingAndVaccinationList";
import DippingAndVaccinationListpage from "./pages/dipppingAndVaccination/dippingAndVaccinationList";
import AddLivestockHealthMedication from "./pages/livestockHealthMedication/addLivestockHealthMedication";
import LivestockHealthMedication from "./pages/livestockHealthMedication/livestockHealthMedicationList";
import AddLivestockService from "./pages/livestockService/addLivestcokService";
import UserList from "./pages/user/userList";
import UserManagement from "./pages/user-management";
import EditUser from "./pages/user/editUser";
import AddMeatInspection from "./pages/meatInspection/addMeatInspection";
import AddIncome from "./pages/income/addIncome";
import AddExpense from "./pages/expense/addExpanse";
import AddPlantHealth from "./pages/healthPlant/addPlantHealth";
import AddPoultryLog from "../src/components/poultryLog/addPoultryLog";
import EggTable from "../src/components/home/tableComponent/eggTable";
import MeatInspectionTable from "../src/components/home/tableComponent/meatInspectionTable";
import MeatInspectionTablePage from "./pages/meatInspection/meatInspectionTablePage";
import LivestockMedicationTable from "../src/components/home/tableComponent/livestockMedicationTable";
import LivestockMedicationTablepage from "./components/livestockHealthMedication/livestockMedicationTablePage";
import LivestockServiceTable from "../src/components/home/tableComponent/livestockServiceTable";
// import LivestockSloughterTable from "../src/components/home/tableComponent/livestockSloughterTable";
// import LivestockSloughterTablePage from "../src/components/home/pages/livestockSloughterTablePage";
import LivestockServiceTablePage from "./components/livestockService/livestockServiceTablePage";
import PoultryLogTable from "../src/components/home/tableComponent/poultryLogTable";
import DippingTable from "../src/components/home/tableComponent/dippingTable";
import AddSchoolProgram from "../src/pages/addForm/schoolSupportProgram.form";
import CropDiseaseTable from "../src/components/home/tableComponent/plantHealthTable";
import AddProjectMonitoring from "../src/pages/addForm/projectMonitoring";
import AddMarketingReport from "../src/pages/addForm/marketingReport-form";
import AddDranageAndIrigation from "../src/pages/addForm/drainage-and-irrigation";
import AddAgroSupportService from "./components/reportForms/addAgroSupportService";
import AddClimateResillencePractice from "./components/reportForms/addClimateResillenceForm";
import AddFarmMarketAccess from "./components/reportForms/addFarmMarketAccess";
import AddFoodSecurity from "./pages/foodSecurity/addFoodSecurity";
// import DippingTablePage from "./components/home/pages/DippingAndVaccinationList/dippingTable-page";
import CropDiseaseTablePage from "./pages/healthPlant/plantHealthTable-page";
import MarketAccessList from "./components/home/tableComponent/marketAccess-table";
import AgroSupportServiceList from "./components/home/tableComponent/AgroSupportService-table";
import ClimateResillenceList from "./components/home/tableComponent/climateResillence-table";
import projectMonitoringList from "./components/home/tableComponent/projectMonitoring-table";
import marketingList from "./components/home/tableComponent/marketing-table";
import ReportIncome from "../src/pages/report-income";
// import EggTablepage from "./eggTablePage";
import PoultryLogTablePage from "./components/poultryLog/poultryLogTablePage";
import DippingTablePage from "./pages/dipppingAndVaccination/dippingTable-page";
import LivestockSloughterTablePage from "./pages/livestockSloughterTablePage";
import EggTablepage from "./pages/eggTablePage";
import ForgotPassword from "./components/forgetPassword/forgetPassword";
import ResetPassword from "./components/forgetPassword/resetPassword";
import ReportExpense from "./pages/report-expense";
import ProductionTable from "./components/home/tableComponent/production-table";
import SchoolProgramTable from "./components/home/tableComponent/schoolSupport-table";
import "bootstrap/dist/css/bootstrap.min.css";
import AddCropDisease from "./pages/healthPlant/addCropDisease";
import FoodSecurityList from "./pages/foodSecurity/foodSecurityList";
import { useEffect } from "react";
import NurseryReportTable from "./pages/nurseryReport/nurseryReportList";
import AddNurseryReport from "./pages/nurseryReport/addNurseryReport";
import EditNurseryReport from "./pages/nurseryReport/editNurseryReport";

function App() {
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);
  return (
    <div className="main">
      <Provider store={store}>
        {/* <HeaderMenu /> */}
        <Routes>
          <Route
            exact
            path="/"
            element={<ProtectedRoute Component={HomePage} />}
          />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/stock"
            element={<ProtectedRoute Component={StockPage} />}
          />
          <Route
            exacteggLog-page
            path="/add-user"
            element={<ProtectedRoute Component={AddUser} />}
          />
          {/* <Route
            exact
            path="/report-income"
            element={<ProtectedRoute Component={ReportIncome} />}
          /> */}
          <Route
            exact
            path="/edit-user/:id"
            element={<ProtectedRoute Component={EditUser} />}
          />
          <Route
            exact
            path="/users"
            element={<ProtectedRoute Component={UserList} />}
          />
          <Route
            exact
            path="/add-production"
            element={<ProtectedRoute Component={AddProduction} />}
          />
          <Route
            exact
            path="/add-school"
            element={<ProtectedRoute Component={AddSchool} />}
          />
          <Route
            exact
            path="/add-vaccination"
            element={<ProtectedRoute Component={AddDippingAndVaccination} />}
          />
          <Route
            exact
            path="/vaccination"
            element={<ProtectedRoute Component={DippingAndVaccinationList} />}
          />
          <Route
            exact
            path="/vaccinationPage"
            element={<ProtectedRoute Component={DippingTable} />}
          />
          <Route
            exact
            path="/add-egg"
            element={<ProtectedRoute Component={AddEggLogPage} />}
          />
          <Route
            exact
            path="/add-livestock"
            element={<ProtectedRoute Component={AddLiveStockPage} />}
          />
          <Route
            exact
            path="/drainage"
            element={<ProtectedRoute Component={AddDrainage} />}
          />
          <Route
            exact
            path="/drainage-table"
            element={<ProtectedRoute Component={DrainageTable} />}
          />
          <Route
            exact
            path="/add-livestock-health"
            element={
              <ProtectedRoute Component={AddLivestockHealthMedication} />
            }
          />
          <Route
            exact
            path="/add-livestock-service"
            element={<ProtectedRoute Component={AddLivestockService} />}
          />
          <Route
            exact
            path="/add-income"
            element={<ProtectedRoute Component={AddIncome} />}
          />
          <Route
            exact
            path="/add-expanse"
            element={<ProtectedRoute Component={AddExpense} />}
          />
          <Route
            exact
            path="/add-user"
            element={<ProtectedRoute Component={AddUser} />}
          />
          <Route
            exact
            path="/user-management"
            element={<ProtectedRoute Component={UserManagement} />}
          />
          <Route
            exact
            path="/report"
            element={<ProtectedRoute Component={ReportPage} />}
          />
          <Route
            exact
            path="/add-meat-inspection"
            element={<ProtectedRoute Component={AddMeatInspection} />}
          />
          <Route
            exact
            path="/add-plant"
            element={<ProtectedRoute Component={AddCropDisease} />}
          />
          <Route
            exact
            path="/add-poultryLog"
            element={<ProtectedRoute Component={AddPoultryLog} />}
          />
          <Route
            exact
            path="/eggLog"
            element={<ProtectedRoute Component={EggTable} />}
          />

          <Route
            exact
            path="/meatInspectionTable"
            element={<ProtectedRoute Component={MeatInspectionTable} />}
          />

          {/* <Route
            exact
            path="/livestockServiceTable-page"
            element={<ProtectedRoute Component={LivestockServiceTablePage} />}
          /> */}

          <Route
            exact
            path="/cropDiseaseTable"
            element={<ProtectedRoute Component={CropDiseaseTable} />}
          />
          <Route
            exact
            path="/meatInspectionTable-page"
            element={<ProtectedRoute Component={MeatInspectionTablePage} />}
          />
          <Route
            exact
            path="/eggLog-page"
            element={<ProtectedRoute Component={EggTablepage} />}
          />
          <Route
            exact
            path="/cropDiseaseTablePage"
            element={<ProtectedRoute Component={CropDiseaseTablePage} />}
          />
          <Route
            exact
            path="/livestockSlaughterTable-page"
            element={<ProtectedRoute Component={LivestockSloughterTablePage} />}
          />
          <Route
            exact
            path="/medicationtable-page"
            element={
              <ProtectedRoute Component={LivestockMedicationTablepage} />
            }
          />
          <Route
            exact
            path="/dippingTable-page"
            element={<ProtectedRoute Component={DippingTablePage} />}
          />
          <Route
            exact
            path="/livestockServiceTable-page"
            element={<ProtectedRoute Component={LivestockServiceTablePage} />}
          />
          <Route
            exact
            path="/poultrylog-table-page"
            element={<ProtectedRoute Component={PoultryLogTablePage} />}
          />
          <Route
            exact
            path="/medication-table"
            element={<ProtectedRoute Component={LivestockMedicationTable} />}
          />

          <Route
            exact
            path="/dippingTable"
            element={<ProtectedRoute Component={DippingTable} />}
          />
          <Route
            exact
            path="/dippingTable"
            element={<ProtectedRoute Component={DippingTable} />}
          />

          <Route
            exact
            path="/livestockServiceTable"
            element={<ProtectedRoute Component={LivestockServiceTable} />}
          />

          <Route
            exact
            path="/stock"
            element={<ProtectedRoute Component={PoultryLogTable} />}
          />
          <Route
            exact
            path="/add-schoolProgram"
            element={<ProtectedRoute Component={AddSchoolProgram} />}
          />
          <Route
            exact
            path="/add-projectMonitoring"
            element={<ProtectedRoute Component={AddProjectMonitoring} />}
          />
          <Route
            exact
            path="/add-marketing"
            element={<ProtectedRoute Component={AddMarketingReport} />}
          />
          <Route
            exact
            path="/add-dranage"
            element={<ProtectedRoute Component={AddDranageAndIrigation} />}
          />
          <Route
            exact
            path="/add-agroSupportService"
            element={<ProtectedRoute Component={AddAgroSupportService} />}
          />
          <Route
            exact
            path="/add-agroClimateResilience"
            element={
              <ProtectedRoute Component={AddClimateResillencePractice} />
            }
          />
          <Route
            exact
            path="/add-FarmMarketAccess"
            element={<ProtectedRoute Component={AddFarmMarketAccess} />}
          />
          <Route
            exact
            path="/add-foodSecurity"
            element={<ProtectedRoute Component={AddFoodSecurity} />}
          />
          <Route
            exact
            path="/foodSecurity"
            element={<ProtectedRoute Component={FoodSecurityList} />}
          />
          <Route
            exact
            path="/income-report"
            element={<ProtectedRoute Component={ReportIncome} />}
          />
          <Route
            exact
            path="/marketAccess-table"
            element={<ProtectedRoute Component={MarketAccessList} />}
          />
          <Route
            exact
            path="/marketing-table"
            element={<ProtectedRoute Component={marketingList} />}
          />
          <Route
            exact
            path="/agroSupportService-table"
            element={<ProtectedRoute Component={AgroSupportServiceList} />}
          />
          <Route
            exact
            path="/projectMonitoring-table"
            element={<ProtectedRoute Component={projectMonitoringList} />}
          />
          <Route
            exact
            path="/climateResilience-table"
            element={<ProtectedRoute Component={ClimateResillenceList} />}
          />
          <Route
            exact
            path="/expense-report"
            element={<ProtectedRoute Component={ReportExpense} />}
          />
          <Route
            exact
            path="/production-table"
            element={<ProtectedRoute Component={ProductionTable} />}
          />
          <Route
            exact
            path="/schoolAgroSupportProgram-table"
            element={<ProtectedRoute Component={SchoolProgramTable} />}
          />
          <Route exact path="/forgotPassword" element={<ForgotPassword />} />
          <Route exact path="/resetPassword" element={<ResetPassword />} />
          <Route
            exact
            path="/add-nurseryReport"
            element={<ProtectedRoute Component={AddNurseryReport} />}
          />
          <Route
            exact
            path="/nurseryReport-table"
            element={<ProtectedRoute Component={NurseryReportTable} />}
          />
          <Route
            exact
            path="/edit-nurseryReport/:id"
            element={<ProtectedRoute Component={EditNurseryReport} />}
          />
        </Routes>
        {/* <Footer /> */}
      </Provider>
    </div>
  );
}

export default App;
