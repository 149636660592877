import React, { useEffect, useState } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import { useFormik } from "formik";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import showNotification from "../../components/notification/notification";
import { handleKeyDown } from "../../helpers/helpers";
import { Button, Modal } from "react-bootstrap";
import {
  editNurseryReport,
  fetchNurseryPlants,
  fetchNurseryReportDetail,
} from "../../services/nurseryReport";

const EditNurseryReport = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [nurseryPlantList, setNurseryPlantList] = useState([]);

  const editNurseryReportFormik = useFormik({
    initialValues: {
      production_category_id: "",
      regionId: JSON.parse(localStorage.getItem("data"))?.regionDetails[0]?._id,
      from_date: "",
      to_date: "",
      opening_balance: "",
      seeds_sown_bin: "",
      seeds_sown_bags: "",
      seeds_germinated: "",
      seeds_transplant: "",
      budded_available: "",
      budded_complete: "",
      grafted_available: "",
      grafted_complete: "",
      offTape_available: "",
      offTape_complete: "",
      trans_to_nursery_stock: "",
      discarded: "",
      donation: "",
      trans_out: "",
      trans_in: "",
      available_for_sale: "",
      plant_order: "",
      amount_sold: "",
      unit_price: "",
      sales: "",
      closing_balance: "",
    },
    onSubmit: async (values) => {
      const sanitizedValues = { ...values };

      // Convert empty string to 0 for number fields
      Object.keys(values).forEach((key) => {
        if (sanitizedValues[key] === "") {
          sanitizedValues[key] = 0;
        }
      });
      const res = await editNurseryReport(id, sanitizedValues);
      if (res.status) {
        navigate("/nurseryReport-table");
        showNotification(res.message, " success");
      } else {
        showNotification(`${res.message}`, "danger");
      }
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  const reset = () => {
    editNurseryReportFormik.resetForm();
  };

  const addUserReset = () => {
    reset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };

  const getNurseryPlants = async () => {
    const res = await fetchNurseryPlants();
    if (res.status) {
      setNurseryPlantList(res.data);
    }
  };

  const getNurseryReportDetail = async () => {
    const res = await fetchNurseryReportDetail(id);
    if (res.status) {
      editNurseryReportFormik.setFieldValue(
        "production_category_id",
        res.data?.production_category_id?._id
      );
      editNurseryReportFormik.setFieldValue(
        "date",
        moment(res.data?.from_date).format("YYYY-MM-DD")
      );
      editNurseryReportFormik.setFieldValue(
        "date",
        moment(res.data?.to_date).format("YYYY-MM-DD")
      );
      editNurseryReportFormik.setFieldValue(
        "opening_balance",
        Number(res.data?.opening_balance)
      );
      editNurseryReportFormik.setFieldValue(
        "seeds_sown_bin",
        Number(res.data?.seeds_sown_bin)
      );
      editNurseryReportFormik.setFieldValue(
        "seeds_sown_bags",
        Number(res.data?.seeds_sown_bags)
      );
      editNurseryReportFormik.setFieldValue(
        "seeds_germinated",
        Number(res.data?.seeds_germinated)
      );
      editNurseryReportFormik.setFieldValue(
        "seeds_transplant",
        Number(res.data?.seeds_transplant)
      );
      editNurseryReportFormik.setFieldValue(
        "budded_available",
        Number(res.data?.budded_available)
      );
      editNurseryReportFormik.setFieldValue(
        "budded_complete",
        Number(res.data?.budded_complete)
      );
      editNurseryReportFormik.setFieldValue(
        "grafted_available",
        Number(res.data?.grafted_available)
      );
      editNurseryReportFormik.setFieldValue(
        "grafted_complete",
        Number(res.data?.grafted_complete)
      );
      editNurseryReportFormik.setFieldValue(
        "offTape_available",
        Number(res.data?.offTape_available)
      );
      editNurseryReportFormik.setFieldValue(
        "offTape_complete",
        Number(res.data?.offTape_complete)
      );
      editNurseryReportFormik.setFieldValue(
        "trans_to_nursery_stock",
        Number(res.data?.trans_to_nursery_stock)
      );
      editNurseryReportFormik.setFieldValue(
        "discarded",
        Number(res.data?.discarded)
      );
      editNurseryReportFormik.setFieldValue(
        "donation",
        Number(res.data?.donation)
      );
      editNurseryReportFormik.setFieldValue(
        "trans_out",
        Number(res.data?.trans_out)
      );
      editNurseryReportFormik.setFieldValue(
        "trans_in",
        Number(res.data?.trans_in)
      );
      editNurseryReportFormik.setFieldValue(
        "available_for_sale",
        Number(res.data?.available_for_sale)
      );
      editNurseryReportFormik.setFieldValue(
        "plant_order",
        Number(res.data?.plant_order)
      );
      editNurseryReportFormik.setFieldValue(
        "amount_sold",
        Number(res.data?.amount_sold)
      );
      editNurseryReportFormik.setFieldValue(
        "unit_price",
        Number(res.data?.unit_price)
      );
      editNurseryReportFormik.setFieldValue("sales", Number(res.data?.sales));
      editNurseryReportFormik.setFieldValue(
        "closing_balance",
        Number(res.data?.closing_balance)
      );
    }
  };

  useEffect(() => {
    getNurseryPlants();
    getNurseryReportDetail();
  }, []);

  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Edit Nursery Report</h3>
          <button onClick={goBack}>
            <img src={Back} alt="" /> BACK
          </button>
        </div>
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>{" "}
      <form onSubmit={editNurseryReportFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock addForm">
            <div className="addFormPage">
              {/* <div className="row1"> */}
              <div className="row">
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Plant</label>
                    <select
                      name="production_category_id"
                      value={
                        editNurseryReportFormik.values.production_category_id
                      }
                      onChange={editNurseryReportFormik.handleChange}
                      required
                    >
                      <option value="">Select Plant</option>
                      {nurseryPlantList &&
                        nurseryPlantList?.map((value) => {
                          return (
                            <option value={value._id}>{value.name}</option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Date</label>
                    <input
                      required
                      name="date"
                      type="date"
                      placeholder="select date"
                      value={editNurseryReportFormik.values.date}
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Opening Balance</label>
                    <input
                      name="opening_balance"
                      type="number"
                      placeholder="Enter Opening Balance"
                      onKeyDown={handleKeyDown}
                      value={editNurseryReportFormik.values.opening_balance}
                      onChange={editNurseryReportFormik.handleChange}
                      required
                      onWheel={(event) => event.currentTarget.blur()}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Seeds Sown Bin</label>
                    <input
                      name="seeds_sown_bin"
                      type="number"
                      placeholder="Enter Seeds Sown Bin"
                      value={editNurseryReportFormik.values.seeds_sown_bin}
                      onChange={editNurseryReportFormik.handleChange}
                      onKeyDown={handleKeyDown}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div className="row">
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Seeds Sown Bags</label>
                    <input
                      name="seeds_sown_bags"
                      type="number"
                      placeholder="Enter Seeds Sown Bags"
                      onKeyDown={handleKeyDown}
                      value={editNurseryReportFormik.values.seeds_sown_bags}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Seeds Germed</label>
                    <input
                      name="seeds_germed"
                      type="number"
                      placeholder="Enter Seeds Germed"
                      value={editNurseryReportFormik.values.seeds_germed}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>T/Plant</label>
                    <input
                      name="t_plant"
                      type="number"
                      placeholder="Enter T/Plant"
                      onKeyDown={handleKeyDown}
                      value={editNurseryReportFormik.values.t_plant}
                      onChange={editNurseryReportFormik.handleChange}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Handling</label>
                    <input
                      name="handling"
                      type="number"
                      placeholder="Enter Handling"
                      onKeyDown={handleKeyDown}
                      value={editNurseryReportFormik.values.handling}
                      onChange={editNurseryReportFormik.handleChange}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Budded Available</label>
                    <input
                      name="budded_available"
                      type="number"
                      placeholder="Enter Budded Available"
                      onKeyDown={handleKeyDown}
                      value={editNurseryReportFormik.values.budded_available}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Budded Completed</label>
                    <input
                      name="budded_complete"
                      type="number"
                      placeholder="Enter Budded Completed"
                      value={editNurseryReportFormik.values.budded_complete}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Grafted Available</label>
                    <input
                      name="grafted_available"
                      type="number"
                      placeholder="Enter Grafted Available"
                      onKeyDown={handleKeyDown}
                      value={editNurseryReportFormik.values.grafted_available}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Grafted Completed</label>
                    <input
                      name="grafted_complete"
                      type="number"
                      placeholder="Enter Grafted Completed"
                      value={editNurseryReportFormik.values.grafted_complete}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Off Tape Available</label>
                    <input
                      name="offTape_available"
                      type="number"
                      placeholder="Enter Off Tape Available"
                      onKeyDown={handleKeyDown}
                      value={editNurseryReportFormik.values.offTape_available}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Off Tape Completed</label>
                    <input
                      name="offTape_complete"
                      type="number"
                      placeholder="Enter Off Tape Completed"
                      value={editNurseryReportFormik.values.offTape_complete}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Trans To Stock</label>
                    <input
                      name="trans_to_stock"
                      type="number"
                      placeholder="Enter Trans To Stock"
                      onKeyDown={handleKeyDown}
                      value={editNurseryReportFormik.values.trans_to_stock}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Disposed</label>
                    <input
                      name="disposed"
                      type="number"
                      placeholder="Enter Disposed"
                      value={editNurseryReportFormik.values.disposed}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Deteriorated</label>
                    <input
                      name="deteriorated"
                      type="number"
                      placeholder="Enter Deteriorated"
                      onKeyDown={handleKeyDown}
                      value={editNurseryReportFormik.values.deteriorated}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Transfer Out</label>
                    <input
                      name="trans_out"
                      type="number"
                      placeholder="Enter Transfer Out"
                      value={editNurseryReportFormik.values.trans_out}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Transfer In</label>
                    <input
                      name="trans_in"
                      type="number"
                      placeholder="Enter Transfer In"
                      onKeyDown={handleKeyDown}
                      value={editNurseryReportFormik.values.trans_in}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Avail For Sale</label>
                    <input
                      name="available_for_sale"
                      type="number"
                      placeholder="Enter Avail For Sale"
                      value={editNurseryReportFormik.values.available_for_sale}
                      onChange={editNurseryReportFormik.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="addFormRow">
                  <label>Plant Order Request</label>
                  <input
                    name="plant_order_request"
                    type="number"
                    placeholder="Enter Plant Order Request"
                    onKeyDown={handleKeyDown}
                    value={editNurseryReportFormik.values.plant_order_request}
                    onChange={editNurseryReportFormik.handleChange}
                  />
                </div>
              </div>
              {/* <div className="col-md-3">
                <div className=" addFormRow">
                  <label>Closing Balance</label>
                  <input
                    name="closing_balance"
                    type="number"
                    placeholder="Enter Closing Balance"
                    value={editNurseryReportFormik.values.closing_balance}
                    onChange={editNurseryReportFormik.handleChange}
                  />
                </div>
              </div> */}
            </div>
          </div>

          <div className="bottomButtons">
            <button type="submit" className="addprdt active" id="addprdt">
              Save
            </button>
          </div>
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditNurseryReport;
