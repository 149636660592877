import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import cardboxbg from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import { useNavigate } from "react-router-dom";
import Shimmer from "../../components/shimmer";
import { useSelector } from "react-redux";
import Notfound from "../../components/data-not-found";
import { downloadCsv } from "../../services/user";
import CSV from "../../assets/images/csv.svg";
import Pdf from "../../assets/images/pdf.svg";
import { fetchNurseryReportList } from "../../services/nurseryReport";
import ClickTooltip from "../../components/home/tableComponent/locatoinTooltip";
import { getLocationInfo } from "../../helpers/helpers";
var fileDownload = require("js-file-download");

const NurseryReportTable = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [loading, setLoading] = useState(true);
  const [nurseryReport, setNurseryReport] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [locationInfo, setLocationInfo] = useState("");

  const role = JSON.parse(localStorage.getItem("data"))?.roles[0]?.shortCode;

  const getEggRecords = async (page) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchNurseryReportList(
      page,
      pageSize,
      regionId,
      startDate,
      endDate
    );
    if (res.status) {
      setLoading(false);
      setNurseryReport({
        header: res.header,
        row: res.row,
      });
      setTotalData(res.count);
    }
  };

  const convertToCsv = async (downloadType) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    downloadCsv(20, downloadType, regionId, startDate, endDate).then((res) => {
      if (res) {
        if (downloadType == 1) {
          createCsv(res);
        } else {
          fileDownload(res, "file.pdf");
        }
      }
    });
  };
  const createCsv = (res) => {
    // Create a Blob with the CSV content
    const blob = new Blob([res], { type: "text/csv;charset=utf-8;" });
    // Create a temporary URL for the Blob
    const csvUrl = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = "file.csv";
    // Simulate a click on the link to trigger the download
    downloadLink.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(csvUrl);
  };

  useEffect(() => {
    getEggRecords(page);
  }, [zoneRefresh, pageSize, page]);

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="theme-container">
        <div className="contentCard2  ">
          <div className="contentCardHeader addProdctHeader tablePageHeader">
            <h3>Nursery Report</h3>
            <div className="tablePageButtons">
              <button onClick={goBack}>
                {" "}
                <img
                  src={Back}
                  alt=""
                />{" "}
                Back
              </button>
              <div className="downloadBtns">
                <button
                  className={
                    totalData === 0
                      ? "fadeDownload downloadtblbtn"
                      : "downloadtblbtn"
                  }
                  onClick={() => {
                    convertToCsv(1);
                  }}
                  type="button">
                  Download CSV
                  <img
                    className="dwlnldIcon"
                    src={CSV}
                    alt=""
                  />
                </button>
                <button
                  className={
                    totalData === 0
                      ? "fadeDownload downloadtblbtn"
                      : "downloadtblbtn"
                  }
                  onClick={() => {
                    convertToCsv(2);
                  }}
                  type="button">
                  Download PDF
                  <img
                    className="dwlnldIcon"
                    src={Pdf}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="cardbg">
            <img
              src={cardboxbg}
              alt="card bg"
            />
          </div>
        </div>
        <div className="politryStockFormtbl">
          <section className="bottom tablePagesection">
            <div
              className="themeCard tableThemeCard"
              style={{ border: "1px solid #666" }}>
              <div className="CardContainer">
                <div className="tableBlockPage">
                  {loading ? (
                    <Shimmer />
                  ) : (
                    <>
                      <table className="table-bordered home-table">
                        <thead>
                          <tr>
                            <th
                              className="incomereportTableHead header_border"
                              colSpan="50"
                              style={{ borderRadius: "10px 10px 0 0" }}>
                              Farming Districts
                            </th>
                          </tr>
                          {/* First row with main headers */}
                          <tr>
                            {nurseryReport?.header.map((value, index) => (
                              <th
                                key={index}
                                rowSpan={value?.child?.length > 0 ? 1 : 2}
                                colSpan={
                                  value?.child?.length > 0
                                    ? value?.child?.length
                                    : 1
                                }
                                className="thd alignmdleft header_border">
                                <strong>{value.header}</strong>
                              </th>
                            ))}
                            {/* {role == "FIELDOFFICER" ? (
                              <th
                                className="thd alignmdleft header_border"
                                rowSpan={2}>
                                <strong>Action</strong>
                              </th>
                            ) : (
                              ""
                            )}  */}
                            <th rowSpan="2">
                              <strong>Updated Location</strong>
                            </th>
                          </tr>

                          {/* Second row with child headers, if any */}
                          {nurseryReport?.header.some(
                            (value) => value?.child?.length > 0
                          ) && (
                            <tr className="homepagetbl">
                              {nurseryReport?.header.map((value, index) =>
                                value?.child?.length > 0
                                  ? value.child.map(
                                      (childValue, childIndex) => (
                                        <th
                                          key={`${index}-${childIndex}`}
                                          className="header_bg header_border">
                                          {childValue}
                                        </th>
                                      )
                                    )
                                  : null
                              )}
                            </tr>
                          )}
                          <tr></tr>
                        </thead>
                        <tbody>
                          {nurseryReport?.row?.length > 0 ? (
                            <>
                              {nurseryReport &&
                                nurseryReport?.row?.map((value) => {
                                  return (
                                    <tr>
                                      <td className="employeeName">
                                        {value.plant}
                                      </td>
                                      <td className="dates">
                                        {moment(value.from_date).format(
                                          "DD-MMM-yyyy"
                                        )}
                                      </td>
                                      <td className="dates">
                                        {moment(value.to_date).format(
                                          "DD-MMM-yyyy"
                                        )}
                                      </td>
                                      <td className="employeeName">
                                        {value.name}
                                      </td>
                                      <td>{value.openingBalance.toFixed(2)}</td>
                                      <td>{value.seeds_sown_bin ?? 0}</td>
                                      <td>{value.seeds_sown_bags ?? 0}</td>
                                      <td>{value.seeds_germinated ?? 0}</td>
                                      <td>{value.seeds_transplant ?? 0}</td>
                                      <td>{value.budded_avail ?? 0}</td>
                                      <td>{value.budded_comp ?? 0}</td>
                                      <td>{value.grafted_avail ?? 0}</td>
                                      <td>{value.grafted_comp ?? 0}</td>
                                      <td>{value.offTape_avail ?? 0}</td>
                                      <td>{value.offTape_comp ?? 0}</td>
                                      <td>
                                        {value.trans_to_nursery_stock ?? 0}
                                      </td>
                                      <td>{value.discarded ?? 0}</td>
                                      <td>{value.donation ?? 0}</td>
                                      <td>{value.trans_in ?? 0}</td>
                                      <td>{value.trans_out ?? 0}</td>
                                      <td>
                                        {value.available_for_sale.toFixed(2) ??
                                          0}
                                      </td>
                                      <td>
                                        {value.plant_order.toFixed(2) ?? 0}
                                      </td>
                                      <td>
                                        {value.amount_sold.toFixed(2) ?? 0}
                                      </td>
                                      <td>
                                        ${value.unit_price.toFixed(2) ?? 0}
                                      </td>
                                      <td>${value.sales.toFixed(2) ?? 0}</td>
                                      <td>
                                        {value.closing_balance.toFixed(2) ?? 0}
                                      </td>
                                      <td className="alignmd">
                                        <ClickTooltip
                                          tooltipText={locationInfo}>
                                          <button
                                            className="btn btn-primary view_location_btn"
                                            onClick={async () => {
                                              let location =
                                                await getLocationInfo(
                                                  value?.extras?.lat,
                                                  value?.extras?.long
                                                );
                                              setLocationInfo(location);
                                            }}>
                                            View Location
                                          </button>
                                        </ClickTooltip>
                                      </td>
                                      {/* {role == "FIELDOFFICER" ? (
                                        <td>
                                          <button
                                            type="button"
                                            class="btn btn-primary"
                                            onClick={() => {
                                              navigate(
                                                `/edit-nurseryReport/${value?._id}`
                                              );
                                            }}
                                          >
                                            Edit
                                          </button>
                                        </td>
                                      ) : (
                                        ""
                                      )} */}
                                    </tr>
                                  );
                                })}
                            </>
                          ) : (
                            <div className="tablenotfound">
                              <Notfound />
                            </div>
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                {totalData > 10 ? (
                  <div className="paginantionBox">
                    <div>
                      <Pagination
                        page={page}
                        count={Math.ceil(Number(totalData / pageSize))}
                        variant="outlined"
                        color="primary"
                        onChange={handleChangePagination}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                    <div className="perPageBox">
                      <select
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                          setPage(1);
                        }}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  " "
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default NurseryReportTable;
