import { requestWithToken } from "../helpers/helpers";

const createNurseryReport = async (payload) => {
  try {
    const response = await requestWithToken("POST", "/nurseryReport", payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editNurseryReport = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/nurseryReport/${id}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchNurseryReportList = async (
  pageNo,
  pageSize,
  regionId,
  startDate,
  endDate
) => {
  try {
    const queryParams = [];
    if (pageNo) queryParams.push(`pageNo=${pageNo}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize}`);
    if (regionId) queryParams.push(`regionId=${regionId}`);
    if (startDate) queryParams.push(`startDate=${startDate}`);
    if (endDate) queryParams.push(`endDate=${endDate}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/nurseryReport/list${queryString}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchNurseryReportDetail = async (id) => {
  try {
    const response = await requestWithToken("GET", `/nurseryReport/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchNurseryPlants = async () => {
  try {
    const response = await requestWithToken("GET", `/nurseryReport/plants`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchOpeningBalance = async (id, from_date) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/nurseryReport/previous/${id}?from_date=${from_date}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  createNurseryReport,
  editNurseryReport,
  fetchNurseryReportList,
  fetchNurseryReportDetail,
  fetchNurseryPlants,
  fetchOpeningBalance,
};
