import React, { useEffect, useState } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import { useFormik } from "formik";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import showNotification from "../../components/notification/notification";
import { handleDoubleTypeKeyDown, handleKeyDown } from "../../helpers/helpers";
import { Button, Modal } from "react-bootstrap";
import {
  createNurseryReport,
  fetchNurseryPlants,
  fetchOpeningBalance,
} from "../../services/nurseryReport";
import {
  availableForSale,
  closingBalance,
  offTapeAvailable,
  salesTotal,
  transferIn,
  transferOut,
} from "../../helpers/nursery_report_formula";

const AddNurseryReport = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [nurseryPlantList, setNurseryPlantList] = useState([]);
  const [opBal, setOpBal] = useState(0);

  const addNurseryReportFormik = useFormik({
    initialValues: {
      production_category_id: "",
      regionId: JSON.parse(localStorage.getItem("data"))?.regionDetails[0]?._id,
      from_date: "",
      to_date: "",
      opening_balance: "",
      seeds_sown_bin: "",
      seeds_sown_bags: "",
      seeds_germinated: "",
      seeds_transplant: "",
      budded_available: "",
      budded_complete: "",
      grafted_available: "",
      grafted_complete: "",
      offTape_available: "",
      offTape_complete: "",
      trans_to_nursery_stock: "",
      discarded: "",
      donation: "",
      trans_out: "",
      trans_in: "",
      available_for_sale: "",
      plant_order: "",
      amount_sold: "",
      unit_price: "",
      sales: "",
      closing_balance: "",
    },
    onSubmit: async (values) => {
      const sanitizedValues = { ...values };

      // Convert empty string to 0 for number fields
      Object.keys(values).forEach((key) => {
        if (sanitizedValues[key] === "") {
          sanitizedValues[key] = 0;
        }
      });

      const res = await createNurseryReport(sanitizedValues);
      if (res.status) {
        navigate("/");
        showNotification(res.message, " success");
      } else {
        showNotification(`${res.message}`, "danger");
      }
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  const reset = () => {
    addNurseryReportFormik.resetForm();
  };

  const addUserReset = () => {
    reset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };

  const getNurseryPlants = async () => {
    const res = await fetchNurseryPlants();
    if (res.status) {
      setNurseryPlantList(res.data);
    }
  };

  const getOpeningBalance = async () => {
    const res = await fetchOpeningBalance(
      addNurseryReportFormik.values.production_category_id,
      addNurseryReportFormik.values.from_date
    );

    if (res.status) {
      if (res.data?.closing_balance != null) {
        // Case 2 & Case 3 (when data is present): Set API closing balance as opening balance
        if (res.data.closing_balance == 0) {
          addNurseryReportFormik.setFieldValue("opening_balance", "");
        } else {
          addNurseryReportFormik.setFieldValue(
            "opening_balance",
            Number(res.data.closing_balance)
          );
        }
        setOpBal(Number(res.data.closing_balance));
      } else {
        //Case 1 & Case 3 (when data is null):
        if (opBal !== 0) {
          // Clear only if previous balance existed (Case 3)
          addNurseryReportFormik.setFieldValue("opening_balance", "");
          setOpBal(0);
        }
        // Do not clear if manually entered (Case 1)
      }
    }
  };

  useEffect(() => {
    getNurseryPlants();
    console.log("value  ", addNurseryReportFormik.values);
  }, []);

  useEffect(() => {
    if (
      (addNurseryReportFormik.values.production_category_id,
      addNurseryReportFormik.values.from_date)
    ) {
      getOpeningBalance();
    }
  }, [
    addNurseryReportFormik.values.production_category_id,
    addNurseryReportFormik.values.from_date,
  ]);

  //use effect to calculate off tape available amount
  useEffect(() => {
    addNurseryReportFormik.setFieldValue(
      "offTape_available",
      offTapeAvailable(
        addNurseryReportFormik.values.budded_complete,
        addNurseryReportFormik.values.grafted_complete
      )
    );
  }, [
    addNurseryReportFormik.values.budded_complete,
    addNurseryReportFormik.values.grafted_complete,
  ]);

  //use effect to calculate transfer in amount
  useEffect(() => {
    addNurseryReportFormik.setFieldValue(
      "trans_in",
      transferIn(
        addNurseryReportFormik.values.seeds_germinated,
        addNurseryReportFormik.values.seeds_transplant,
        addNurseryReportFormik.values.budded_available,
        addNurseryReportFormik.values.grafted_available,
        addNurseryReportFormik.values.trans_to_nursery_stock
      )
    );
  }, [
    addNurseryReportFormik.values.seeds_germinated,
    addNurseryReportFormik.values.seeds_transplant,
    addNurseryReportFormik.values.budded_available,
    addNurseryReportFormik.values.grafted_available,
    addNurseryReportFormik.values.trans_to_nursery_stock,
  ]);

  //use effect to calculate transfer out amount
  useEffect(() => {
    addNurseryReportFormik.setFieldValue(
      "trans_out",
      transferOut(
        addNurseryReportFormik.values.discarded,
        addNurseryReportFormik.values.donation
      )
    );
  }, [
    addNurseryReportFormik.values.discarded,
    addNurseryReportFormik.values.donation,
  ]);

  //use effect to calculate availble for sale amount
  useEffect(() => {
    addNurseryReportFormik.setFieldValue(
      "available_for_sale",
      availableForSale(
        addNurseryReportFormik.values.opening_balance,
        addNurseryReportFormik.values.trans_in,
        addNurseryReportFormik.values.trans_out
      )
    );
  }, [
    addNurseryReportFormik.values.opening_balance,
    addNurseryReportFormik.values.trans_in,
    addNurseryReportFormik.values.trans_out,
  ]);

  //use effect to calculate sales amount
  useEffect(() => {
    addNurseryReportFormik.setFieldValue(
      "sales",
      salesTotal(
        addNurseryReportFormik.values.amount_sold,
        addNurseryReportFormik.values.unit_price
      )
    );
  }, [
    addNurseryReportFormik.values.amount_sold,
    addNurseryReportFormik.values.unit_price,
  ]);

  //use effect to calculate closing balance
  useEffect(() => {
    addNurseryReportFormik.setFieldValue(
      "closing_balance",
      closingBalance(
        addNurseryReportFormik.values.available_for_sale,
        addNurseryReportFormik.values.amount_sold
      )
    );
  }, [
    addNurseryReportFormik.values.available_for_sale,
    addNurseryReportFormik.values.amount_sold,
  ]);

  const [isFormFilled, setIsFormFilled] = useState(false);

  // List of field names that should be checked
  const editableFields = [
    "production_category_id",
    "from_date",
    "to_date",
    "opening_balance",
    "seeds_sown_bin",
    "seeds_sown_bags",
    "seeds_germinated",
    "seeds_transplant",
    "budded_available",
    "budded_complete",
    "grafted_available",
    "grafted_complete",
    "offTape_complete",
    "trans_to_nursery_stock",
    "donation",
    "discarded",
    "plant_order",
    "amount_sold",
    "unit_price",
  ];

  // Check if any of the editable fields have a non-default value
  useEffect(() => {
    const hasValue = editableFields.some(
      (field) =>
        addNurseryReportFormik.values[field] !== "" &&
        addNurseryReportFormik.values[field] !== 0
    );
    setIsFormFilled(hasValue);
  }, [addNurseryReportFormik.values]);

  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Nursery Report</h3>
          <button onClick={goBack}>
            <img src={Back} alt="" /> BACK
          </button>
        </div>
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>{" "}
      <form onSubmit={addNurseryReportFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock addForm">
            <div className="addFormPage">
              {/* <div className="row1"> */}
              <div className="row">
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Plant</label>
                    <select
                      name="production_category_id"
                      value={
                        addNurseryReportFormik.values.production_category_id
                      }
                      onChange={addNurseryReportFormik.handleChange}
                      required
                    >
                      <option value="">Select Plant</option>
                      {nurseryPlantList &&
                        nurseryPlantList?.map((value) => {
                          return (
                            <option value={value._id}>{value.name}</option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>From Date</label>
                    <input
                      required
                      name="from_date"
                      type="date"
                      placeholder="select date"
                      value={addNurseryReportFormik.values.from_date}
                      // max={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={addNurseryReportFormik.handleChange}
                      onKeyDown={(e) => e.preventDefault()} // Prevent manual input
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>To Date</label>
                    <input
                      required
                      name="to_date"
                      type="date"
                      placeholder="select date"
                      value={addNurseryReportFormik.values.to_date}
                      min={moment(
                        new Date(addNurseryReportFormik.values.from_date)
                      ).format("YYYY-MM-DD")}
                      onChange={addNurseryReportFormik.handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Opening Balance</label>
                    <input
                      required
                      name="opening_balance"
                      type="number"
                      placeholder="Enter Opening Balance"
                      onKeyDown={handleDoubleTypeKeyDown}
                      value={addNurseryReportFormik.values.opening_balance}
                      min="1"
                      step="any"
                      onChange={addNurseryReportFormik.handleChange}
                      onWheel={(event) => event.currentTarget.blur()}
                      disabled={!!opBal}
                    />
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div className="row">
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Seeds Sown Bin</label>
                    <input
                      name="seeds_sown_bin"
                      type="number"
                      placeholder="Enter Seeds Sown Bin"
                      value={addNurseryReportFormik.values.seeds_sown_bin}
                      onChange={addNurseryReportFormik.handleChange}
                      onKeyDown={handleKeyDown}
                      onWheel={(event) => event.currentTarget.blur()}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Seeds Sown Bags</label>
                    <input
                      name="seeds_sown_bags"
                      type="number"
                      placeholder="Enter Seeds Sown Bags"
                      onKeyDown={handleKeyDown}
                      value={addNurseryReportFormik.values.seeds_sown_bags}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Seeds Germinated</label>
                    <input
                      name="seeds_germinated"
                      type="number"
                      placeholder="Enter Seeds Germinated"
                      value={addNurseryReportFormik.values.seeds_germinated}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Seeds Transplant</label>
                    <input
                      name="seeds_transplant"
                      type="number"
                      placeholder="Enter Seeds Transplant"
                      onKeyDown={handleKeyDown}
                      value={addNurseryReportFormik.values.seeds_transplant}
                      onChange={addNurseryReportFormik.handleChange}
                      onWheel={(event) => event.currentTarget.blur()}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Budded Available</label>
                    <input
                      name="budded_available"
                      type="number"
                      placeholder="Enter Budded Available"
                      onKeyDown={handleKeyDown}
                      value={addNurseryReportFormik.values.budded_available}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Budded Completed</label>
                    <input
                      name="budded_complete"
                      type="number"
                      placeholder="Enter Budded Completed"
                      value={addNurseryReportFormik.values.budded_complete}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Grafted Available</label>
                    <input
                      name="grafted_available"
                      type="number"
                      placeholder="Enter Grafted Available"
                      onKeyDown={handleKeyDown}
                      value={addNurseryReportFormik.values.grafted_available}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Grafted Completed</label>
                    <input
                      name="grafted_complete"
                      type="number"
                      placeholder="Enter Grafted Completed"
                      value={addNurseryReportFormik.values.grafted_complete}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Off Tape Available</label>
                    <input
                      name="offTape_available"
                      type="number"
                      placeholder="Enter Off Tape Available"
                      onKeyDown={handleKeyDown}
                      value={addNurseryReportFormik.values.offTape_available}
                      onChange={addNurseryReportFormik.handleChange}
                      disabled
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Off Tape Completed</label>
                    <input
                      name="offTape_complete"
                      type="number"
                      placeholder="Enter Off Tape Completed"
                      value={addNurseryReportFormik.values.offTape_complete}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Transfer To Nursery Stock</label>
                    <input
                      name="trans_to_nursery_stock"
                      type="number"
                      placeholder="Enter Transfer To Nursery Stock"
                      onKeyDown={handleKeyDown}
                      value={
                        addNurseryReportFormik.values.trans_to_nursery_stock
                      }
                      onChange={addNurseryReportFormik.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Discarded</label>
                    <input
                      name="discarded"
                      type="number"
                      placeholder="Enter Discarded"
                      value={addNurseryReportFormik.values.discarded}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Donation</label>
                    <input
                      name="donation"
                      type="number"
                      placeholder="Enter Donation"
                      onKeyDown={handleKeyDown}
                      value={addNurseryReportFormik.values.donation}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Transfer In</label>
                    <input
                      name="trans_in"
                      type="number"
                      placeholder="Enter Transfer In"
                      onKeyDown={handleKeyDown}
                      value={addNurseryReportFormik.values.trans_in}
                      onChange={addNurseryReportFormik.handleChange}
                      disabled
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Transfer Out</label>
                    <input
                      name="trans_out"
                      type="number"
                      placeholder="Enter Transfer Out"
                      value={addNurseryReportFormik.values.trans_out}
                      onChange={addNurseryReportFormik.handleChange}
                      disabled
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Available For Sale</label>
                    <input
                      name="available_for_sale"
                      type="number"
                      placeholder="Enter Available For Sale"
                      value={addNurseryReportFormik.values.available_for_sale}
                      onChange={addNurseryReportFormik.handleChange}
                      disabled
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Plant Order</label>
                    <input
                      name="plant_order"
                      type="number"
                      placeholder="Enter Plant Order"
                      onKeyDown={handleKeyDown}
                      value={addNurseryReportFormik.values.plant_order}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Amount Sold</label>
                    <input
                      name="amount_sold"
                      type="number"
                      placeholder="Enter Amount Sold"
                      value={addNurseryReportFormik.values.amount_sold}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                      onKeyDown={handleDoubleTypeKeyDown}
                      min="1"
                      step="any"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Unit Price</label>
                    <input
                      name="unit_price"
                      type="number"
                      placeholder="Enter Unit Price"
                      value={addNurseryReportFormik.values.unit_price}
                      onChange={addNurseryReportFormik.handleChange}
                      required
                      onKeyDown={handleDoubleTypeKeyDown}
                      min="1"
                      step="any"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="addFormRow">
                    <label>Sales</label>
                    <input
                      name="sales"
                      type="number"
                      placeholder="Enter Sales"
                      onKeyDown={handleKeyDown}
                      value={addNurseryReportFormik.values.sales}
                      onChange={addNurseryReportFormik.handleChange}
                      disabled
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className=" addFormRow">
                    <label>Closing Balance</label>
                    <input
                      name="closing_balance"
                      type="number"
                      placeholder="Enter Closing Balance"
                      value={addNurseryReportFormik.values.closing_balance}
                      onChange={addNurseryReportFormik.handleChange}
                      disabled
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottomButtons">
            <button type="submit" className="addprdt active" id="addprdt">
              Add Report
            </button>
            <button
              type="button"
              className="reset"
              onClick={handleShow}
              disabled={!isFormFilled}
            >
              {" "}
              Reset
            </button>
          </div>
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddNurseryReport;
