/* 
****** Formula For Nursery Plant Report ******

1. Off Tape Available = Budded Complete + Grafted Complete

2. Transfer In = ( Seeds Germinated - Seeds Transplant ) + Seeds Transplant + Budded Available + Grafted Available + Transfer To Stock

3. Transfer Out = Discarded + Donation

4. Available For Sale = Opening Balance + Transfer In - Transfer Out 

5. Sales = Amount Sold * Unit Price 

6. Closing Balance = Available For Sale - Amount Sold
*/

export const offTapeAvailable = (buddedComplete = 0, graftedComplete = 0) => {
  return Number(buddedComplete) + Number(graftedComplete);
};

export const transferIn = (
  seedsGerminated = 0,
  seedsTransplant = 0,
  buddedAvailable = 0,
  graftedAvailable = 0,
  transToNurseryStock = 0
) => {
  return (
    Number(seedsGerminated) -
    Number(seedsTransplant) +
    Number(seedsTransplant) +
    Number(buddedAvailable) +
    Number(graftedAvailable) +
    Number(transToNurseryStock)
  );
};

export const transferOut = (discarded = 0, donation = 0) => {
  return Number(discarded) + Number(donation);
};

export const availableForSale = (
  openingBalance = 0,
  transIn = 0,
  transOut = 0
) => {
  const total = Number(openingBalance) + Number(transIn) - Number(transOut);
  return total < 0 ? 0 : Number(total.toFixed(2));
};

export const salesTotal = (amountSold = 0, unitPrice = 0) => {
  const total = Number(amountSold) * Number(unitPrice);
  return Number(total.toFixed(2));
};

export const closingBalance = (availableForSale = 0, amountSold = 0) => {
  const total = Number(availableForSale) - Number(amountSold);
  return total < 0 ? 0 : Number(total.toFixed(2));
};
